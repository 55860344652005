
import { defineComponent, PropType } from 'vue'
import { Block } from '@interfaces/BlockTypes'
import AppActions from '@constants/AppActions'
import { isExternalLink } from '@helpers/helpers'

export default defineComponent({
  name: 'CommonSocialMediaCards',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
      observer: null as any,
      featureSelected: 0,
      isPaused: true,
      featureStates: {},
      isDialogOpen: false,
      selected: this.block?.category?.[0],
      categoryContainer: null as HTMLElement | null,
      isLoading: true,
      showLeftArrow: false,
      showRightArrow: false,
      isExternalLink,
    }
  },
  mounted() {
    this.selected = this.block?.category?.[0]
    this.categoryContainer = this.$refs?.categoryContainer as HTMLElement
    this.$nextTick(() => {
      const element = this.$refs?.categoryContainer as HTMLElement
      if (element) {
        this.categoryOverflow(element)
        this.checkOverflow(element)
        if (typeof window !== 'undefined') {
          window.addEventListener('resize', this.handleResize)
        }
      }
    })
    this.isLoading = false
  },
  methods: {
    async heroButtonClicked() {
      const textAndImage: any =
        this.selected?.features[this.featureSelected]?.textAndImage
      if (textAndImage?.enableTracking) {
        await this.$store.dispatch(AppActions.TRACK_SIGN_UP_EVENT, {
          context: this,
          location:
            this.selected?.features[this.featureSelected]?.textAndImage
              ?.ctaButton?.trackingLocation,
          link:
            this.selected?.features[this.featureSelected]?.textAndImage
              ?.ctaButton?.link || null,
        })
      }
    },
    handleResize() {
      const element = this.$refs?.categoryContainer as HTMLElement
      if (element) {
        this.checkOverflow(element)
      }
    },
    selectFeature(direction: 'next' | 'previous') {
      const numberOfFeatures = Object.keys(this.selected.features).length
      const isNext = direction === 'next'
      const isPrevious = !isNext

      if (
        (isNext && this.featureSelected < numberOfFeatures - 1) ||
        (isPrevious && this.featureSelected > 0)
      ) {
        this.featureSelected += isNext ? 1 : -1
      } else {
        const categoryArray = Object.values(this.block.category || [])
        const currentCategoryIndex = categoryArray.indexOf(this.selected)
        if (
          (isNext && currentCategoryIndex < categoryArray.length - 1) ||
          (isPrevious && currentCategoryIndex > 0)
        ) {
          this.selected =
            categoryArray[currentCategoryIndex + (isNext ? 1 : -1)]
        } else {
          this.selected = isNext
            ? categoryArray[0]
            : categoryArray[categoryArray.length - 1]
        }
        this.featureSelected = isNext
          ? 0
          : Object.keys(this.selected.features).length - 1
      }
    },
    selectNextFeature() {
      this.selectFeature('next')
    },
    selectPreviousFeature() {
      this.selectFeature('previous')
    },

    categoryClicked(category: any) {
      this.selected = category
      this.featureSelected = 0
    },
    categoryOverflow(element: HTMLElement) {
      const categoryContainer = element
      return categoryContainer.scrollWidth > categoryContainer.clientWidth
    },
    setArrowVisibility(visible: boolean) {
      this.showLeftArrow = visible
      this.showRightArrow = visible
    },
    checkOverflow(element: HTMLElement) {
      const isOverflowing = this.categoryOverflow(element)
      const isNonTouchDevice = window.matchMedia('(hover: hover)').matches
      if (isNonTouchDevice && isOverflowing) {
        this.setArrowVisibility(isOverflowing)
        element.classList.add('justify-start', 'spacing')
        element.classList.remove('justify-center')

        this.showRightArrow = true
        this.showLeftArrow = true
      } else {
        element.classList.add('justify-center')
        element.classList.remove('spacing', 'justify-start')

        this.setArrowVisibility(false)
      }
    },
    scrollLeft() {
      const container = this.$refs?.categoryContainer as HTMLElement
      if (container) {
        container.scrollBy({ left: -600, behavior: 'smooth' })
      }
    },
    scrollRight() {
      const container = this.$refs?.categoryContainer as HTMLElement
      if (container) {
        container.scrollBy({ left: 600, behavior: 'smooth' })
      }
    },
  },
  computed: {
    featuresCount(): number {
      return Object.keys(this.selected.features).length
    },
    currentFeatureIndex(): number {
      return this.featureSelected + 1
    },
    categoryLength(): number {
      return this.block.category ? Object.keys(this.block.category).length : 0
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    if (this.observer) {
      this.observer.disconnect()
    }
  },
})
